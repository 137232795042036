<template>
	<div class="wrapper">
		<div>
			<div class="header-block">
				<div class="header-block_tag" v-if="title">{{ title }}</div>
			</div>
			
			<div v-if="!loading">
				<list :games="games"></list>
			</div>
			<div v-else class="loading"></div>
		</div>
	</div>
</template>

<script>
	import List from "../components/List/List";
	
	export default {
		components: {
			List
		},
		
		data() {
			return {
				loading: true,
				tag: this.$route.params.tag,
				games: null,
				title: '',
				auth: window.auth
			}
		},
		
		created() {
			this.axios.get('/games/?limit=10000&tags=' + this.tag).then(({data}) => {
				this.games = data.data;
				
				this.loading = false;
				
				this.getTagTitle();
			});
		},
		
		methods: {
			getTagTitle() {
				let tags = this.games[0].tags;
				
				tags.forEach((el) => {
					if (el.id == this.tag) {
						this.title = el.name;
					}
				})
			}
		}
	}
</script>

<style scoped>

</style>
