<template>
  <div>
	  <main-slider></main-slider>
	  
	  <div v-if="loading.all">
		  <div class="game-block" 
			   v-for="(category, key) in sortedCategories" 
			   :key="'category' + category.id">
			  <div>
				  <div class="wrapper">
					  <div class="header-block">
						  <h2 class="header-block_title bold">{{ category.name }}</h2>
					  </div>
					
					  <tab v-if="category.id !== 3" :headings="category.keys" :active-key="Object.keys(category.keys)[0]">
						  <template :slot="gamesKey" v-for="(games, gamesKey) in category.games">
							  <div class="game-block_row">
								  <div class="game-block_col" v-for="(game, index) in games">
									  <cart :game="game" :index="index"></cart>
								  </div>
							  </div>
						  </template>
					  </tab>
					
					  <div class="game-block_row" v-else>
						  <div class="game-block_col" v-for="(game, index) in popularGames">
							  <cart :game="game" :index="index"></cart>
						  </div>
					  </div>
				  </div>
			  </div>
			  
			  <div class="category-block" v-if="key == 1">
				  <div class="wrapper">
					  <div class="category-block_row" :class="{__ios: isiOS()}">
						  <div class="category-block_col">
							  <router-link to="/platform/online" class="category-block_item __1" style="background-image: url(/img/c3.jpg)">
								  <div class="category-block_data">
									  <div class="category-block_title">Онлайн игры</div>
									  <div class="category-block_text">Сотни игр на любой вкус! Играйте онлайн без скачивания на компьютере или телефоне</div>
								  </div>
							  </router-link>
						  </div>
						  <div class="category-block_col">
							  <router-link to="/platform/free" class="category-block_item __2" style="background-image: url(/img/c1.jpg)">
								  <div class="category-block_data">
									  <div class="category-block_title">Бесплатные игры</div>
									  <div class="category-block_text">Уникальная подборка онлайн-игр без регистрации</div>
								  </div>
							  </router-link>
							  <router-link v-if="!isiOS()" to="/platform/android" class="category-block_item __3" style="background-image: url(/img/c4.jpg)">
								  <div class="category-block_data">
									  <div class="category-block_title">Android игры</div>
									  <div class="category-block_text">Лучшие игры на Android во всех жанрах! Качайте и открывайте для себя новый мир игр</div>
								  </div>
							  </router-link>
						  </div>
					  </div>
				  </div>
			  </div>
		  </div>
	  </div>
	  <div v-else class="loading"></div>
  </div>
</template>

<script>
	import Tab from "@/components/Tab/Tab";
	import MainSlider from "@/components/MainSlider/MainSlider";
	import MobileSlider from "@/components/MainSlider/MobileSlider";
	import Cart from "@/components/Cart/Cart";
	
	export default {
		components: {
			Tab,
			MainSlider,
			MobileSlider,
			Cart
		},
		
		data() {
			return {
				categories: {},
				popularGames: [],
				loading: {
					all: false,
					_key: 0
				},
			}
		},
		
		computed: {
			sortedCategories() {
				if (!this.loading.all) return {};
				
				let categories = {};
				
				for (let cat in this.categories) {
					categories[this.categories[cat].key] = this.categories[cat];
				}
				
				return categories;
			}
		},
		
		created() {
			this.axios.get('/games/categories/').then(({data}) => {
				if (!this.categories) 
					this.categories = {};
				
				data.forEach((item, key) => {
					this.categories[item.id] = {
						id: item.id,
						name: item.name,
						games: null,
						key: key
					}
					
					this.initGames(item.id);
				});
			})
		},
		
		methods: {
			initGames(id) {
				if (id === 3) {
					this.axios.get('/games/?categories=' + id + '&limit=1000').then(({data}) => {
						this.popularGames = data.data;
						
						this.loading._key++;

						if (this.loading._key === Object.keys(this.categories).length) {
							this.loading.all = true;
						}
					})
				} else {
					this.axios.get('/games/?categories=' + id + '&limit=1000&rank=5').then(({data}) => {
						this.categories[id].games = this.sortGames(data.data);
						this.categories[id].keys = this.setKeys(Object.keys(this.categories[id].games));

						this.loading._key++;

						if (this.loading._key === Object.keys(this.categories).length) {
							this.loading.all = true;
						}
					})
				}
			},
			
			sortGames(games) {
				let gamesObj = {};
				
				games.forEach((game) => {
					if (!gamesObj[game.genre_name]) {
						gamesObj[game.genre_name] = [game];
					} else {
						gamesObj[game.genre_name].push(game)
					}
				});
				
				return gamesObj;
			},
			
			setKeys(keys) {
				let obj = {};
				
				for (let item in keys) {
					obj[keys[item]] = keys[item];
				}
				
				return obj;
			},
			isiOS() {
				return [
						'iPad Simulator',
						'iPhone Simulator',
						'iPod Simulator',
						'iPad',
						'iPhone',
						'iPod'
					].includes(navigator.platform)
					|| (navigator.userAgent.includes("Mac") && "ontouchend" in document)
			}
		},
	}
</script>
