<template>
	<div class="main-slider">
		<div class="wrapper">
			<VueSlickCarousel  v-bind="settings">
				<div class="main-slider_item">
					<div class="main-slider_image">
						<img src="/img/b4.jpg" alt="">
					</div>
					
					<div class="main-slider_button">
						<router-link to="/game/96" class="button __type-1">Играть</router-link>
					</div>
				</div>
				<div class="main-slider_item">
					<div class="main-slider_image">
						<img src="/img/b2.jpg" alt="">
					</div>
					
					<div class="main-slider_button">
						<router-link to="/game/102" class="button __type-1">Играть</router-link>
					</div>
				</div>
				<div class="main-slider_item">
					<div class="main-slider_image">
						<img src="/img/b3.jpg" alt="">
					</div>
					
					<div class="main-slider_button">
						<router-link to="/game/98" class="button __type-1">Играть</router-link>
					</div>
				</div>
			</VueSlickCarousel>
		</div>
	</div>
</template>

<script>
	import VueSlickCarousel from 'vue-slick-carousel'
	import 'vue-slick-carousel/dist/vue-slick-carousel.css'
	import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
	
	export default {
		components: {
			VueSlickCarousel
		},
		
		data() {
			return {
				settings: {
					arrows: true,
					centerMode: true,
					dots: true,
					centerPadding: '0px',
					responsive: [
						{
							breakpoint: 1340,
							settings: {
								centerMode: false
							}
						}
					]
				}
			}
		}
	}
</script>

<style scoped>

</style>
