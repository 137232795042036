<template>
	<router-link :to="{name: 'game', params: {id: game.id}}" class="game-block_item">
		<div class="game-block_image">
			<img v-if="index !== 0 || isMobile" :src="game.logo_image" alt="">
			<img v-else :src="game.big_logo_image" alt="">
		</div>
		<div class="game-block_content">
			<div class="game-block_name">{{ game.name }}</div>
			<div class="game-block_cat" :class="['__' + game.genre_id]">{{ game.genre_name}}</div>
		</div>
	</router-link>
</template>

<script>
	export default {
		props: ['game', 'index'],
		
		data() {
			return {
				isMobile: window.innerWidth < 768
			}
		}
	}
</script>

<style scoped>

</style>
