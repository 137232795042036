<template>
	<div id="app">
		<header-block></header-block>
		
		<div class="content">
			<div class="content_main">
				<router-view :key="$route.fullPath" />
				
				<footer-block></footer-block>
			</div>
		</div>
	</div>
</template>

<script>
	import Header from "./components/Layout/Header";
	import Footer from "./components/Layout/Footer";

	export default {
		name: 'App',

		components: {
			HeaderBlock: Header,
			FooterBlock: Footer
		},

		data() {
			return {
				
			}
		},

		created() {
			
		}
	}
</script>

<style lang="less">
	@import "assets/less/main";
</style>
